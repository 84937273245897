'use client';

import { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
const GlobalErrorPage: AppErrorPage = ({
  error,
  reset
}) => {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);
  return <div data-sentry-component="GlobalErrorPage" data-sentry-source-file="global-error.tsx">
      <h2>Something went wrong!</h2>
      <button type="button" onClick={() => reset()}>
        Try again
      </button>
    </div>;
};
export default GlobalErrorPage;